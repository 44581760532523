
import React from "react"

import NnreturnPage from "../../components/negosyonow/controls/nnreturneditor";

const ReturnPage = ({location}) => {
	return <NnreturnPage
				location={location}
				allowadd={true}
				methodid={0}
				encodertype={1}
			/>
}


export default ReturnPage;
